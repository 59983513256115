import { render, staticRenderFns } from "./CustomerService.vue?vue&type=template&id=4d7a0df2&scoped=true"
var script = {}
import style0 from "./CustomerService.vue?vue&type=style&index=0&id=4d7a0df2&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d7a0df2",
  null
  
)

export default component.exports